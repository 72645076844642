<template>
    <section>
        <slot
            :on-click-button="onClickButton"
            :reset-active-button="resetActiveButton"
            :active-button="activeButton"
        />
    </section>
</template>

<script>
    export default {
        data() {
            return {
                activeButton: null,
            };
        },

        methods: {
            onClickButton(id) {
                this.activeButton = id;
            },
            resetActiveButton() {
                this.activeButton = null;
            },
        },
    };
</script>
