<template>
    <section>
        <slot :on-hover="onHover" :set-swiper="setSwiper" :on-slide-change="onSlideChange" :index="index" />
    </section>
</template>

<script>
    export default {
        data() {
            return {
                swiper: null,
                index: 0,
            };
        },

        methods: {
            setSwiper(swiper) {
                this.swiper = swiper;
            },
            onSlideChange({ activeIndex }) {
                this.index = activeIndex;
            },
            onHover(index) {
                this.index = index;
                this.swiper.slideTo(index);
            },
        },
    };
</script>
