<template>
    <Teleport to="body">
        <transition name="fade">
            <div v-if="open" class="fixed inset-0 flex bg-black/50 px-8 z-50" @click.prevent="$emit('close')">
                <div class="container my-auto">
                    <div class="max-w-2xl mx-auto">
                        <div class="bg-[#A29C7D] text-white p-12 xl:p-20 rounded-2xl">
                            <header class="flex">
                                <div class="flex-1 mr-12">
                                    <slot name="header" />
                                </div>

                                <button @click.prevent="$emit('close')">
                                    <span class="sr-only">Close</span>
                                    <close-icon class="block w-8 h-8" />
                                </button>
                            </header>

                            <section class="mt-10">
                                <slot name="body" />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script>
    import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
    import CloseIcon from '@/img/icons/xmark-sharp-thin.svg';

    export default {
        components: {
            CloseIcon,
        },

        props: {
            open: {
                type: Boolean,
                default: false,
            },
        },

        emits: [ 'close' ],

        watch: {
            open(value) {
                if (value) {
                    disableBodyScroll(this.$el, {
                        reserveScrollBarGap: true,
                    });
                } else {
                    enableBodyScroll(this.$el);
                }
            },
        },
    };
</script>

<style lang="postcss" scoped>
    .modal {

    }
</style>
