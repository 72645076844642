import gql from 'graphql-tag';

export const GET_PUBLICATIONS_QUERY = gql`
    query Projects($limit: Int, $offset: Int, $year: [QueryArgument!]) {
        entries: publicationsEntries(limit: $limit, offset: $offset, year: $year, orderBy: "year desc") {
            ... on publications_default_Entry {
                id
                title
                year @formatDateTime(format: "Y M", timezone: "Australia/Sydney")
                doi
                publicationAuthors
                publicationDescription
                publication {
                    url
                }
            }
        }
    }
`;
