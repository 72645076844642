<template>
    <div class="relative">
        <slot />

        <div class="overlay" :class="{ 'overlay--hidden': hidden }">
            <div class="w-full">
                <p class="h5 uppercase mb-6 lg:mb-8">Sensitive Content</p>
                <p class="text-sm lg:text-base">This image contains sensitive content which some people may find offensive or disturbing.</p>

                <button
                    type="button"
                    class="h5 text-xs uppercase bg-primary-500 rounded-xl px-6 py-1.5 mt-10"
                    @click.prevent="hidden = false"
                >
                    View Image
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                hidden: true,
            };
        },
    };
</script>

<style lang="postcss" scoped>
    .overlay {
        @apply flex items-center px-10 lg:px-20 absolute inset-0 text-center text-white opacity-0 bg-black/50;
        @apply transition-opacity ease-in-out duration-300;
        backdrop-filter: blur(60px);
    }

    .overlay--hidden {
        @apply opacity-100;
    }
</style>
