<template>
    <section
        v-infinite-scroll="onLoadMore"
        class="py-20 bg-secondary-200"
        infinite-scroll-disabled="disableLazyLoad"
        infinite-scroll-distance="400"
        infinite-scroll-immediate-check="false"
    >
        <div class="container">
            <div class="mb-8">
                <div class="inline-block relative">
                    <select
                        v-model="year"
                        class="uppercase text-black bg-transparent border border-secondary-500 rounded-full pl-4 pr-8 py-1 focus:outline-none appearance-none"
                    >
                        <option :value="null">Year</option>
                        <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
                    </select>

                    <chevron-down class="absolute block w-3 h-auto align-y right-3" />
                </div>
            </div>

            <ol class="list-decimal font-medium max-w-6xl leading-7 pl-4">
                <li v-for="publication in entries" :key="publication.id" class="pl-12 mb-8 last:mb-0" data-aos="fade-up">
                    <h2 class="font-medium inline">{{ publication.publicationAuthors || publication.title }}</h2>
                    <p class="font-light italic inline ml-1">
                        {{ publication.publicationDescription }}
                        <span class="text-secondary-500 font-medium not-italic whitespace-nowrap">{{ publication.year }}</span>
                    </p>
                    <p class="font-light">
                        <a :href="publication.publication.url" class="underline">
                            <abbr class="lowercase" title="Digital Object Identifier">DOI</abbr>:
                            {{ publication.doi }}
                        </a>
                    </p>
                </li>
            </ol>
        </div>
    </section>
</template>

<script>
    import infiniteScroll from 'vue3-infinite-scroll-good';
    import ChevronDown from '@/img/icons/angle-down-regular.svg?component';
    import { GET_PUBLICATIONS_QUERY } from '@/js/graphql/queries/publications';

    export default {
        directives: {
            infiniteScroll,
        },

        components: {
            ChevronDown,
        },

        data() {
            const years = [];
            const currentYear = new Date().getFullYear();

            for (let year = currentYear; year >= 2006; year--) {
                years.push(year);
            }

            return {
                year: null,
                years,
                entries: [],
                page: 0,
                entriesPerPage: 24,
                disableLazyLoad: true,
            };
        },

        computed: {
            filter() {
                const variables = {};

                if (this.year) {
                    variables.year = [
                        'and',
                        `>= ${this.year}-01-01`,
                        `<= ${this.year}-12-31`,
                    ];
                }

                return variables;
            },
        },

        watch: {
            year() {
                this.page = 0;
            },
        },

        apollo: {
            entries: {
                query: GET_PUBLICATIONS_QUERY,
                debounce: 500,
                variables() {
                    return {
                        limit: this.entriesPerPage,
                        offset: 0,
                        ...this.filter,
                    };
                },
                update(data) {
                    this.disableLazyLoad = data.entries.length < this.entriesPerPage;
                    return data.entries;
                },
            },
        },

        methods: {
            onLoadMore() {
                if (this.$apollo.queries.entries.loading) {
                    return;
                }

                this.page++;
                const offset = this.page * this.entriesPerPage;

                console.log(offset);

                this.$apollo.queries.entries.fetchMore({
                    variables: {
                        limit: this.entriesPerPage,
                        offset,
                        ...this.filter,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        const entries = [
                            ...previousResult.entries,
                            ...fetchMoreResult.entries,
                        ];

                        this.animate = false;
                        this.disableLazyLoad = fetchMoreResult.entries.length < this.entriesPerPage;

                        return {
                            entries,
                        };
                    },
                });
            },
        },
    };
</script>
